import "./App.css";
import * as React from "react";
import {
  Box,
  Button,
  Stack,
  Card,
  CardContent,
  Typography,
  CardActions,
  Toolbar,
} from "@mui/material";

const PrototypeCard = (props) => {
  return (
    <Card variant="outlined" sx={{ width: 300 }}>
      <React.Fragment>
        <CardContent>
          <Typography variant="h6" component="div">
            {props.name}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {props.shortText}
          </Typography>
          <Typography variant="body1">{props.description}</Typography>
        </CardContent>
        <CardActions>
          <Button size="small" href={props.link}>
            Visit prototype
          </Button>
        </CardActions>
      </React.Fragment>
    </Card>
  );
};

function App() {
  return (
    <div className="App">
      <Box sx={{ maxWidth: 1200 }}>
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            align="start"
            sx={{ display: { sm: "block" }, px: 2, py: 3 }}
          >
            THERIF: A Framework for Generating Themes for Readability with
            Iterative Feedback
          </Typography>
        </Toolbar>
        <Typography
          align="start"
          sx={{ px: 5 }}
          style={{ whiteSpace: "pre-line" }}
        >
          Digital reading applications give readers the ability to customize
          fonts, sizes, and spacings, all of which have been shown to improve
          various aspects of reading, including reading comfort, comprehension,
          and speed. However, tweaking these text features can be challenging,
          especially given their interactions on the final look and feel of the
          text.{"\n"}
          {"\n"}
          Our solution is to offer readers preset combinations of font,
          character, word and line spacing, which we bundle together into
          reading themes. To arrive at a recommended set of reading themes, we
          present our THERIF framework, which combines crowdsourced text
          adjustments, ML-driven clustering of text formats, and design
          sessions. We show that after four iterations of our framework, we
          converge on a set of three COR themes (Compact, Open, and Relaxed)
          that match diverse readers' needs, when evaluating the reading speeds,
          comprehension scores, and preferences of hundreds of readers with and
          without dyslexia, using crowdsourced experiments.
        </Typography>
        <Typography variant="h6" align="start" sx={{ px: 5, py: 2 }}>
          Paper
        </Typography>
        <Typography align="start" sx={{ px: 5 }}>
          <Button href="https://arxiv.org/abs/2303.04221">Full paper</Button>
          <br />
          <Button href="https://doi.org/10.1145/3544549.3585679">Late-breaking work</Button>
        </Typography>
        <Typography variant="h6" align="start" sx={{ px: 5, py: 2 }}>
          Final Themes
        </Typography>
        <Typography align="start" sx={{ px: 5 }}>
          <Button href="https://themes-demo.netlify.app">Final Themes</Button>
          <br />
        </Typography>
        <Typography variant="h6" align="start" sx={{ px: 5, py: 2 }}>
          Study Prototypes
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          sx={{ px: 5 }}
          spacing={2}
        >
          <PrototypeCard
            name="Pilot Study"
            shortText="Find text settings that matter"
            link="https://chi23-proto-pilot-interface.netlify.app"
            description="In pilot study, participants had access to a wide range of controls for tailoring reading interfaces to their preference. "
          />
          <PrototypeCard
            name="Crowdworkers"
            shortText="Rate and refine"
            link="https://chi23-proto-participant-interface.netlify.app"
            description="In the main study, participants review themes from the previous iteration and select one to fine-tune further."
          />
          <PrototypeCard
            name="Designers "
            shortText="Refine and supplement"
            link="https://chi23-proto-designer-interface.netlify.app/"
            description="Designers use the Participant Prototype to review the themes and use the Designer Prototype to create supplementary reading themes."
          />
          <PrototypeCard
            name="Reading Effectiveness"
            shortText="Speed, comprehension, comfort"
            link="https://readingtests.app"
            description="A separate group of participants read in all final reading themes and a control theme, their speed, comprehension, and comfort ratings recorded."
          />
        </Stack>
      </Box>
    </div>
  );
}

export default App;
